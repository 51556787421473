<template>
    <div class="p-4 mb-4 mt-5 bg-body-tertiary rounded-3">
        <h1 class="display-5 fw-bold">Credits</h1>
        <p class="col-md-12 fs-4">
            Huge thanks <a href="https://www.reddit.com/user/aarongeis/">/u/aarongeis</a> who did the original work on this project collecting and organizing all the image. 
            The current site is based on the <a href="https://drive.google.com/drive/folders/1-gvvg3NPSs1TfGZgx8ISIn1QkgBfo-vY?usp=sharing">Google Drive link</a>
            (<a href="https://www.reddit.com/r/Highrepublic/comments/vv5paz/the_high_republic_visual_guide_reimagined/">reddit post</a>).
        </p>
    </div>
</template>

<script>
export default {};
</script>
